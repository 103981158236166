<template>
  <div
    class="
      stat-height
      flex
      items-center
      justify-start
      m-auto
      w-full
      sm:m-0
      border-2 border-gray-200
      rounded-lg
      bg-white
    "
  >
    <div class="w-full items-start
      justify-center inline-flex h-full" v-for="(stat, index) of stats" :key="index">
      <div class="w-0.5 h-full bg-gray-200" v-if="index > 0" />
      <div class="flex items-center justify-start flex-1 h-full px-2 lg:px-6" >
        <div class="inline-flex flex-col items-start justify-start">
          <p class="text-xs sm:text-sm leading-tight text-gray-500">{{ stat.name }}</p>
          <div class="inline-flex items-end justify-start">
            <div class="flex flex-col space-x-2 items-center justify-start">
              <p class="text-lg font-semibold leading-normal">{{ stat.value }}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  props: ['stats'],
  setup() {
    const route = useRoute();
    const getParams = computed(() => route.params.id);

    //const { vault } = toRefs(props)

    /* const calculations = computed(() => {
      const ownerFractions = (vault.value.supply * vault.value.asset_owner_fractions) / 100;
      const jxFractions = (vault.value.supply * vault.value.jx_fractions) / 100;
      const salesFractions = ((vault.value.supply - (ownerFractions + jxFractions)) * vault.value.max_fractions) / 100;
      const totalVault = vault.value.reserved_price * vault.value.supply;

      return {ownerFractions, jxFractions, salesFractions, totalVault};
    }); */



    return {
      getParams,
      //calculations
    };
  },
};
</script>
<style>

.stat-height {
  height: 108px;

}

</style>