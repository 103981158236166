<template>
<div 
  :class="`h-${iconSize} w-${iconSize} justify-center items-center`"
> 

 <svg viewBox="0 0 11 14"  :class="`h-${iconSize } w-${iconSize}`" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.83264 7.84582L5.5 13.5V10.3256L9.83264 7.84582Z" fill="#C8B2F5" stroke="#3441C0" stroke-width="0.461979" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.16736 7.84582L5.5 13.5V10.3256L1.16736 7.84582Z" fill="#EECBC0" stroke="#3441C0" stroke-width="0.461979" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.16655 7.19085L5.5 5.29492V9.617L1.16655 7.19085Z" fill="#87A9F0" stroke="#3441C0" stroke-width="0.462094" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.83296 7.19085L5.49951 5.29492V9.617L9.83296 7.19085Z" fill="#CAB3F5" stroke="#3441C0" stroke-width="0.462094" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.16736 7.19045L5.5 0.5V5.29453L1.16736 7.19045Z" fill="#EECBC0" stroke="#3441C0" stroke-width="0.461979" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.83264 7.19045L5.5 0.5V5.29453L9.83264 7.19045Z" fill="#B8FBF6" stroke="#3441C0" stroke-width="0.461979" stroke-miterlimit="7" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



</div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'
export default {
  props: ['color', 'size'],
  setup(props) {
    const { color, size } = toRefs(props)

    const iconColor = ref("#A0A4B1")
    const iconSize = ref("5")

    onMounted(() => {
      iconColor.value = color.value || "#A0A4B1"
      iconSize.value = size.value || "5"
    })

    watch(color, (val) => {
      iconColor.val = val
    })
    watch(size, (val) => {
      iconSize.val = val
    })

    return {
      iconColor,
      iconSize
    }
  },
}
</script>