<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="10" height="10" rx="5" fill="white"/>
    <rect x="1" y="1" width="10" height="10" rx="5" :stroke="stroke" stroke-width="2"/>
    </svg>

</template>
<script>
export default {
    props: ['stroke'],
    setup() {
        
    },
}
</script>