<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    class="cursor-pointer"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2383_61256)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.7071 15.2929C24.0976 15.6834 24.0976 16.3166 23.7071 16.7071L20.4142 20L23.7071 23.2929C24.0976 23.6834 24.0976 24.3166 23.7071 24.7071C23.3166 25.0976 22.6834 25.0976 22.2929 24.7071L18.2929 20.7071C17.9024 20.3166 17.9024 19.6834 18.2929 19.2929L22.2929 15.2929C22.6834 14.9024 23.3166 14.9024 23.7071 15.2929Z"
        :fill="colorChange"
      />
      <rect
        x="3"
        y="2"
        width="36"
        height="36"
        rx="18"
        :stroke="colorChange"
        stroke-width="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2383_61256"
        x="0"
        y="0"
        width="42"
        height="42"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2383_61256"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2383_61256"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
import { computed, toRefs } from '@vue/reactivity'
export default {
    props: ['color'],
    setup(props) {
        const { color } = toRefs(props)
        const colorChange = computed(() => {
            return color ? color.value : 'black';
        })

        return {
            colorChange
        }
    },
}
</script>