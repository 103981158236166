<template>
    <div class="rounded-lg overflow-hidden shadow-xl c-card ">
        <!-- <header class="h-14 bg-gray-50 flex items-center justify-center border-b border-gray-300">
            <span v-html="title"></span>
        </header> -->
        <div class="h-24" style="
      background: url('/images/cirkol-background.png'), rgba(40, 224, 189, 0.82);
      background-blend-mode: multiply;
      background-size: cover;
    ">
            <div class="flex flex-col items-center h-full">
                <div class="flex">
                    <img class="w-[25px]" src="@/assets/images/Ethereum.svg" alt="Ethereum Logo">
                    <span class="pr-[25px] text-6xl leading-none font-extrabold tracking-tight text-altOne" v-html="price"></span>
                </div>
                <span v-html="title"></span>
            </div>
        </div>
        <div class="p-4 lg:p-8 bg-white">
            <ul class="space-y-2">
                <li v-for="(sentence, index) of content" :key="index" class="text-sm leading-4 font-medium lg:text-base lg:leading-6 border-b border-gray-100 last:border-b-0 pb-4 flex items-center space-x-1" :class="{'text-gray-400': !sentence.active, 'text-gray-500': sentence.active}">
                    <span>
                        <img v-if="sentence.active" src="@/assets/images/CheckCircle.svg" alt="Check Mark">
                        <circle-icon v-else :stroke="'#9CA3AF'" />
                    </span>
                    <div class="flex w-full justify-between">
                        <span v-html="sentence.content"></span>
                        <span v-if="sentence.required" class="text-pink font-bold">*</span></div></li>
            </ul>
        </div>
        <footer class="card-footer bg-white flex items-center justify-center px-4 space-x-2">
            <!-- <prev-button @click="goPrev" v-if="index > 0 && !desktop" :color="'#000000'" /> -->
            <Button v-if="status == 'active'" :customClass="'w-full'" :btnStyle="'primary'" :size="'medium'" @click="closeIt">JOIN YOUR CIRKOL</Button>
            <Button v-else-if="status == 'pending'" :customClass="'w-full'" :btnStyle="'disabled'" :size="'medium'" @click="closeIt">LOCKED</Button>
            <Button v-else :customClass="'w-full'" :btnStyle="'disabled'" :size="'medium'" @click="closeIt">MINTED</Button>
            
            <!-- <next-button @click="goNext" v-if="index < 5 && !desktop" :color="'#000000'" /> -->
        </footer>
    </div>
</template>
<script>

import Button from '@/components/Layouts/Button.vue'
import CircleIcon from '../Icons/CircleIcon.vue'
export default {
    props: ['price', 'title', 'content', 'index', 'desktop', "status"],
    components: {Button, CircleIcon},
    emits: ['on:next', 'on:prev', 'on:close'],
    setup(props, {emit}) {     
        
        const goPrev = () => {
            emit('on:prev')
        }

        const goNext = () => {
            emit('on:next')
        }
        
        const closeIt = () => {
            emit('on:close')
        }

        return {
            goPrev,
            goNext,
            closeIt,
            
        }
    },
}
</script>
<style scoped>
    .card-footer { height: 70px; }
    .c-card { max-width: 400px; margin: 0 auto;}
</style>