<template>
  <div class="gog-app bg-white h-full">

    <Header @on:login="showLoginPopUp" @on:logout="logout" :outside-color="menuEffect" />
    <div
      class="body"
      :class="{
        'bg-white': getRouteName == 'Home' || getRouteName == 'LearnMore',
        'bg-white': getRouteName != 'Home' && getRouteName != 'LearnMore',
      }"
    >
      <router-view @on:login="showLoginPopUp" :registered="registrationDone" @on:scroll="scrollEvent" @on:componentMounted="init"/>
      <Modal />
      <toast />
    </div>
    <Footer />
    <loading />
    <ConnectWallet
      :isModal="true"
      :login_modal="showLogin"
      @on:close="closeLoginModal"
      @on:connect="setEvents($event)"
    />
  </div>
</template>

<script>
import Header from "@/components/Layouts/Header.vue";
import Footer from "@/components/Layouts/Footer.vue";
import Toast from "./components/Shared/Toast.vue";
import { onMounted, ref, computed } from '@vue/runtime-core';
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import Modal from "./components/Modals/Modal.vue";
import Loading from "./components/Shared/Loading.vue";

import ConnectWallet from "./components/Modals/ConnectWallet.vue";

export default {
  computed: {
    getRouteName() {
      return this.$route.name;
    },
  },
  components: {
    Header,
    Footer,
    Toast,
    Modal,
    Loading,
    ConnectWallet,
  },
  setup() {
    const chains = {
      "0x539": "local",
      "0x5": "goerli",
      "0x4": "rinkeby",
      "0x1": "mainnet",
    };

    const menuEffect = ref('text-black');

    const ph = document.createElement("script");
    //console.log('------');
    //console.log(process.env.VUE_APP_ENV);
    //console.log(process.env.VUE_APP_NETWORK);
    //console.log('------');
    if (process.env.VUE_APP_ENV == "staging") {
      ph.text =
        "(function(d,t,g,k){var ph=d.createElement(t),s=d.getElementsByTagName(t)[0],t=(new URLSearchParams(window.location.search)).get(k);t&&localStorage.setItem(k,t);t=localStorage.getItem(k);ph.type='text/javascript';ph.async=true;ph.defer=true;ph.charset='UTF-8';ph.src=g+'&v='+(new Date()).getTime();ph.src+=t?'&'+k+'='+t:'';s.parentNode.insertBefore(ph,s)})(document,'script','//7thw.co/?p=2173&ph_apikey=e73a582e7c6d9638bf49527e02b1af7d', 'ph_access_token');;";
    } 
    else {
      // ph.text =
      //   "(function(d,t,g,k){var ph=d.createElement(t),s=d.getElementsByTagName(t)[0],t=(new URLSearchParams(window.location.search)).get(k);t&&localStorage.setItem(k,t);t=localStorage.getItem(k);ph.type='text/javascript';ph.async=true;ph.defer=true;ph.charset='UTF-8';ph.src=g+'&v='+(new Date()).getTime();ph.src+=t?'&'+k+'='+t:'';s.parentNode.insertBefore(ph,s)})(document,'script','//7thw.co/?p=2066&ph_apikey=1e04c691e6fbcaacf9da49deac11dbd9','ph_access_token');";
    }
    document.body.appendChild(ph);

    const showLogin = ref(false);
    const registrationDone = ref(false);

    const store = useStore();
    const route = useRoute();
    const referral = computed(() => route.query.referral)

    const showLoginPopUp = () => {
      //console.log("here");
      showLogin.value = true;
    };

    const closeLoginModal = () => {
      showLogin.value = false;
      
    };

    const logout = () => {
       registrationDone.value = false;
    }

    const setEvents = async (event) => {


      registrationDone.value = event.registered;
      
      try {

        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        console.log(chainId);

        checkChain(chains[chainId]);

        window.ethereum.on("chainChanged", (chainId) => {
          checkChain(chains[chainId]);
        });

        window.ethereum.on("accountsChanged", () => {
          store.dispatch("user/logoutUser");
          showLogin.value = false;
                    
        });

      } catch (error) {
        console.log(error);
      }
    } 

    const scrollEvent = (event) => {
      menuEffect.value = event.color
    }

    const checkChain = (chain) => {
      if (chain !== process.env.VUE_APP_NETWORK) {
        store.dispatch("NotificationStore/SET_MODAL_TITLE", {
          title: "You are on the wrong network.",
        });
        store.dispatch("NotificationStore/SET_MODAL_MESSAGE", {
          message: `This site is intended to work in ${process.env.VUE_APP_NETWORK} chain. Please change your network and reconnect your wallet.`,
        });
        store.dispatch("NotificationStore/SET_MODAL_BUTTONS", {
          buttons: [
            { name: "OK", btnStyle: "primary", action: function () {
              store.dispatch("NotificationStore/SET_OPEN_MODAL");
            } },
            
          ],
        });
        store.dispatch("NotificationStore/SET_OPEN_MODAL");
      }
    };

    const init = async() => {
      console.log('here');
      setTimeout(() => {
        if (referral.value !== "" && referral.value !== null) {
          store.dispatch("session/saveReferral", referral.value)
        }
      }, 500)
      await store.dispatch('blockchain/initWallets');
      await store.dispatch('blockchain/getBlockChain');
      //const address = store.getters['blockchain/getCurrentAddress'];
      const provider = store.getters['blockchain/getCurrentProvider'];
      setEvents({provider: provider, registered: false});
    }

    onMounted(async () => {
      await init();
    })

    return {
      showLoginPopUp,
      closeLoginModal,
      showLogin,
      setEvents,
      registrationDone,
      logout,
      scrollEvent,
      menuEffect,
      init
    };
  },
};
</script>

<style scoped>
.body {
  min-height: calc(100vh - 400px);
  position: relative;
}
.gog-app {
  position: relative;
}
</style>
