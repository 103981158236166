import axios from 'axios';

const app_url = process.env.VUE_APP_FUNCTIONS_URL;

const findWhere = async(collection, data) => {
    const result = await axios.post(app_url+'find_where?collection='+collection, data);
    return result.data;
}
const findWherePaged = async(collection, page, data) => {
    const result = await axios.post(app_url+'find_where_paged?collection='+collection+"&page="+page, data);
    return result.data;
}


const findAllSubcollections = async(collection, subcollection, ref) => {
    const result = await axios.get(app_url+'find_all_subcollections?collection='+collection+'&ref='+ref+'&subcollection='+subcollection);
    return result.data;
}

const findAllWhereSubcollection = async(collection, subcollection, data) => {
    const result = await axios.post(app_url+'find_all_where_subcollection?collection='+collection+'&subcollection='+subcollection, data);
    return result.data;
}

const findWhereSubcollection = async(collection, subcollection, ref, data) => {
    const result = await axios.post(app_url+'find_where_subcollection?collection='+collection+'&ref='+ref+'&subcollection='+subcollection, data);
    return result.data;
}

const findOne = async(collection, ref) => {
    const result = await axios.get(app_url+'find_one?collection='+collection+"&ref="+ref);
    return result.data;
}

const findOneSubcollection = async(collection, ref, subcollection, subref) => {
    const result = await axios.get(app_url+'find_one_subcollection?collection='+collection+'&subcollection='+subcollection+'&ref='+ref+'&subref='+subref);
    return result.data;
}



const findAll = async(collection) => {
    const result = await axios.get(app_url+'find_all?collection='+collection);
    return result.data;
}
const findAllPaged = async(collection, page) => {
    const result = await axios.get(app_url+'find_by_page?collection='+collection+"&page="+page);
    return result.data;
}
const editObject = async(collection, ref, data) => {
    const result = await axios.post(app_url+'edit_object?collection='+collection+"&ref="+ref, data);
    return result.data;
}

const editObjectSubcollection = async(collection, ref, subcollection, subref, data) => {
    const result = await axios.post(app_url+'edit_subcollection?collection='+collection+"&ref="+ref+'&subcollection='+subcollection+"&subref="+subref, data);
    return result.data;
}
const deleteObject = async(collection, ref) => {
    const result = await axios.get(app_url+'delete_object?collection='+collection+"&ref="+ref);
    return result.data;
}

const deleteSubcollection = async(collection, ref, subcollection, subref) => {
    const result = await axios.delete(app_url+'delete_subcollection?collection='+collection+"&ref="+ref+'&subcollection='+subcollection+"&subref="+subref);
    return result.data;
}

const createObject = async(collection, data) => {
    const result = await axios.post(app_url+'create_object?collection='+collection, data);
    return result.data;
}
const createSubCollection = async(collection, ref, subCollection, data) => {
    console.log("EL")
    const result = await axios.post(app_url+'create_subcollection?parent='+collection+"&parentRef="+ref+"&collection="+subCollection, data);
    return result.data;
}
const syncMoralis = async() => {
    const result = await axios.get(app_url+'sync_moralis');
    return result.data.length;
}

export {
    findWhere,
    findWherePaged,
    findOne,
    findAll,
    findAllPaged,
    editObject,
    deleteObject,
    createObject,
    syncMoralis,
    createSubCollection,
    findAllWhereSubcollection,
    findWhereSubcollection,
    findOneSubcollection,
    findAllSubcollections,
    editObjectSubcollection,
    deleteSubcollection
};