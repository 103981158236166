const cards = {
    C: {
        price: 2,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">1 - 250</span> MINTS</span>',
        items: [
            {
                active: true,
                content: 'Founding Member status'
            },
            {
                active: true,
                content: 'Premium transportation with stay'
            },
            {
                active: true,
                content: 'Exclusive founding member events',
            },
            {
                active: true,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">6</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">3x</span> Governance voting power'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">20%</span> mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">20%</span> Discount of on-site purchases'
            }
        ]
    },
    I: {
        price: 4,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">251 - 750</span> MINTS</span>',
        items: [
            {
                active: true,
                content: 'Founding Member status'
            },
            {
                active: true,
                content: 'Airport transfer with stay'
            },
            {
                active: true,
                content: 'Exclusive founding member events',
            },
            {
                active: true,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">5</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">2x</span> Governance voting power'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">15%</span> mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">15%</span> Discount of on-site purchases'
            }
        ]
    },
    R: {
        price: 6,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">751 - 1500</span> MINTS</span>',
        items: [
            {
                active: false,
                content: 'Founding Member status'
            },
            {
                active: false,
                content: 'Premium transportation with stay'
            },
            {
                active: false,
                content: 'Exclusive founding member events',
            },
            {
                active: false,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">4</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: 'Governance voting power'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">10%</span> mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">10%</span> Discount of on-site purchases'
            }
        ]
    },
    K: {
        price: 8,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">1501 - 2500</span> MINTS</span>',
        items: [
            {
                active: false,
                content: 'Founding Member status'
            },
            {
                active: false,
                content: 'Premium transportation with stay'
            },
            {
                active: false,
                content: 'Exclusive founding member events',
            },
            {
                active: false,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">3</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: 'Governance voting power'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">5%</span> mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">10%</span> Discount of on-site purchases'
            }
        ]
    },
    O: {
        price: 10,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">2501 - 3750</span> MINTS</span>',
        items: [
            {
                active: false,
                content: 'Founding Member status'
            },
            {
                active: false,
                content: 'Premium transportation with stay'
            },
            {
                active: false,
                content: 'Exclusive founding member events',
            },
            {
                active: false,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">2</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: 'Governance voting power'
            },
            {
                active: false,
                content: '20% mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">10%</span> Discount of on-site purchases'
            }
        ]
    },
    L: {
        price: 12,
        title: '<span class="text-white text-xs leading-4 font-medium tracking-wider uppercase">MNFT <span class="text-lg leading-7 font-bold">3751 - 5500</span> MINTS</span>',
        items: [
            {
                active: false,
                content: 'Founding Member status'
            },
            {
                active: false,
                content: 'Premium transportation with stay'
            },
            {
                active: false,
                content: 'Exclusive founding member events',
            },
            {
                active: false,
                content: 'VIP access to all CIRKOL events '
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">1</span> Nights exclusive access / year'
            },
            {
                active: true,
                content: 'Governance voting power'
            },
            {
                active: false,
                content: '20% mNFT mint referral commission'
            },
            {
                active: true,
                content: '<span class="text-sm leading-5 font-bold text-black">10%</span> Discount of on-site purchases'
            }
        ]
    }
}

export default cards;