<template>
    <Teleport to="body">
        <div v-if="localShow">
            <div class="fixed z-50 bg-black  inset-0"></div>
            <div class="pl-2 fixed lg:left-1/2 lg:rounded-xl modal-size top-0 lg:top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 z-50 shadow-lg h-screen overflow-scroll lg:h-auto lg:overflow-hidden" >
                
               
                <div class=" lg:pt-0">
                    <div class="flex items-center justify-between py-4">
                        <class-letter v-for="(item, index) of series" :key="index" :letter="item.letter" :active="item.active" :color="'white'" @click="selectLetter(index)" />
                    </div>
                     <swiper
                        :slides-per-view="1"
                        :space-between="50"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="(serie, key, index) of levels" :key="index">
                        
                            <series-card :status="series[index].status" :price="serie.price" :title="serie.title" :content="serie.items" :index="index" @on:prev="goPrev" @on:next="goNext" @on:close="close" />
                        
                        </swiper-slide>
                     </swiper>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script>
import { ref, toRefs, watch } from '@vue/runtime-core'
import SeriesCard from '@/components/cards/SeriesCard'
import levels from '../../firebase/cirkol_levels';
import ClassLetter from '../Shared/ClassLetter.vue';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/swiper.min.css';

console.log(levels);
export default {
  components: { SeriesCard, ClassLetter, Swiper, SwiperSlide },
  props: ['show'],
  emits: ['on:confirms', 'on:close'],
    setup(props, {emit}) {
        const { show } = toRefs(props);
        const localShow = ref(show.value);
        const swiperInstance = ref(null);
        

        watch(show, (value) => {
            localShow.value = value
        })

        const close = () => {
            localShow.value = false;
            emit("on:close");
        }
        
        //statusses = done, active, pending
        const series = ref([{letter: 'C', active: true, status: "active"},{letter: 'I', active: false, status: "pending"},{letter: 'R', active: false, status: "pending"},{letter: 'K', active: false, status: "pending"},{letter: 'O', active: false, status: "pending"},{letter: 'L', active: false, status: "pending"}]);

        const onSwiper = (swiper) => {
            console.log(swiper);
            swiperInstance.value = swiper;
        }

        const selectLetter = (index, isSwiper = null) => {
            series.value.forEach(item => {
                item.active = false;
            })
            series.value[index].active = true;
            if (!isSwiper)
                swiperInstance.value.slideTo(index)
        }

        const onSlideChange = (event) => {
           selectLetter(event.realIndex, true)
        }

        const goPrev = () => {
            swiperInstance.value.slidePrev();
        }

         const goNext = () => {
            swiperInstance.value.slideNext();
        }

        

        return {
            localShow,
            close,
            series,
            levels,
            selectLetter,
            onSwiper,
            onSlideChange,
            goPrev,
            goNext
        }
    },
}
</script>
<style scoped>
.modal-size {
    width: 1024px;
}

@media screen and (max-width: 767px) {
    .modal-size {
        width: 100%;
    }
}
</style>