<template>
    <div class="flex items-center">
    <div class="flex items-center h-m-42">
    <input
        id="Promotions"
        name="newsletter"
        type="checkbox"
        v-model="minted"
        @change="sendToParent"
        class="focus:ring-primary-500 h-m-42 w-m-42 text-primary-600 border-gray-300 rounded"
    />
    </div>
    <div class="ml-3 text-sm flex flex-col items-start">
    <label for="Promotions" class="text-base leading-6 font-normal text-gray-700"
        >MINTED</label
    >
    </div>
</div>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
    emits: ['on:filter'],
    setup(props, {emit}) {
        const minted = ref(false);
        const sendToParent = () => {
            console.log(minted.value);

            emit('on:filter', {minted: minted.value})
        }
        return {
            minted,
            sendToParent
        }
    },
}
</script>