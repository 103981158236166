<template>
  <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 29.25L28.5 9.75M9.5 9.75L28.5 29.25" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>
<script>
export default {
  setup() {
    
  },
}
</script>