<template>
    <div class="flex items-center justify-center font-montserrat font-bold  circle-letter rounded-full border-2 hover:text-pink hover:border-pink cursor-pointer" :class="[normalColor]">{{letter}}</div>
</template>
<script>
import { computed, ref, toRefs } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
export default {
    props: ['letter', 'active', 'color'],
    setup(props) {
        const { active, color } = toRefs(props);
        const localActive = ref(active.value);
        const normalColor = computed(() => {
            if (localActive.value) {
                return 'text-pink border-pink'
            } else {
                return 'text-'+color.value+' border-'+color.value;
            }
        })
        watch(active, (value) => {
            localActive.value = value;
        });

        return {
            localActive,
            normalColor
        }
    },
}
</script>
<style scoped>
.circle-letter {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 28px;
}
</style>