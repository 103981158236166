<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.4999 1.59961C6.29076 1.59961 4.4999 3.39047 4.4999 5.59961V7.19961C3.61625 7.19961 2.8999 7.91595 2.8999 8.79961V12.7996C2.8999 13.6833 3.61625 14.3996 4.4999 14.3996H12.4999C13.3835 14.3996 14.0999 13.6833 14.0999 12.7996V8.79961C14.0999 7.91595 13.3835 7.19961 12.4999 7.19961H6.0999V5.59961C6.0999 4.27413 7.17442 3.19961 8.4999 3.19961C9.6171 3.19961 10.5579 3.96371 10.8243 4.99902C10.9345 5.4269 11.3706 5.68449 11.7985 5.57436C12.2264 5.46423 12.484 5.02808 12.3738 4.6002C11.9298 2.87515 10.3648 1.59961 8.4999 1.59961Z" :fill="fill"/>
</svg>

</template>
<script>
export default {
    props: ['fill'],

    setup() {
        
    },
}
</script>