<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog 
      as="div" 
      static 
      class="fixed z-50 inset-0 overflow-y-auto" 
      @close="closeModal" 
      :open="open"
    >
      <div class="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild 
          as="template" 
          enter="ease-out duration-300" 
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
          enter-to="opacity-100 translate-y-0 sm:scale-100" 
          leave="ease-in duration-200" 
          leave-from="opacity-100 translate-y-0 sm:scale-100" 
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div 
            class="inline-flex flex-col items-center justify-center align-bottom w-screen h-screen sm:h-auto sm:w-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6 lg:max-w-4xl"
          >
            <div class="sm:block absolute top-0 left-6 pt-4 pr-4">
              <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 font-inter">
                  {{title}}
                </DialogTitle>
            </div>
            <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
              <a href="#" @click.prevent="closeModal" class="text-black hover:text-pink w-10 h-10"><icon-close /></a>
            </div>
            <div class="mt-10 overflow-auto">
              
              <div class="mt-3 text-center sm:mt-5">
                <div class="mt-2 text-left space-y-4 text-base leading-7 font-normal" v-html="message">
                  
                </div>
              </div>
            </div>
            <div v-if="buttons" class="mt-5 sm:mt-6 flex flex-wrap justify-center w-full">
              <div v-for="btn in buttons" :key="btn.name" class="flex-grow">
                <Button :customClass="'w-full'" :name="btn.name" @on:submit="btn.action" :btnStyle="btn.btnStyle">{{btn.name}}</Button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, onMounted, ref, watch, } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useStore } from 'vuex'
import Button from '../Layouts/Button.vue'
import IconClose from '../Icons/IconClose.vue'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Button,
    IconClose,
  },
  setup() {
    const store = useStore();
    const open = ref(false);
    const openModal = computed(
      () => store.state.NotificationStore.openModal
    );
    const message = computed(
      () => store.state.NotificationStore.message
    );
    const title = computed(
      () => store.state.NotificationStore.title
    );
    const buttons = computed(
      () => store.state.NotificationStore.buttons
    );

    const closeModal = () => {
      store.dispatch("NotificationStore/SET_OPEN_MODAL");
    }

    onMounted(() => {
      open.value = openModal.value; 
    })

    watch(openModal, (val) => {
      open.value = val
      
    })

    return {
      open,
      closeModal,
      message,
      title,
      buttons,
    }
  },
}
</script>