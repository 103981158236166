import { set, del } from "idb-keyval";
import { db, getClientByBlockChain } from "../../firebase/firebase";

import { createObject, editObject, createSubCollection, findAllSubcollections, deleteSubcollection } from "../../firebase/elaborate_firestore";
import { getUserByAddress } from "../../firebase/clients";

// initial state
const state = () => ({
  user: {
    type:'Client',
    dbRef: null,
    username: "",
    about: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    street: "",
    city: "",
    state: "",
    zipCode: "", 
    company: null,
    linkedin: null,
    instagram: null,
    twitter: null,
    site: null,
    newsletter_push: false,
    email_push: false,
    sms_push: false,
    avatar: "",
    blockchainAddress: null,
    provider: null,
    hasSetAllowance: false,
    nfts: [],
    offers: [],
    bids: [],
    affiliate: {
      affiliate_code: "",
      affiliate_referral_link: "",
      comission: 0,
      comission_wallet: "",
      status: false
    },
    whitelist: {
      qty: 0,
      price: 20,
      expiration: ''
    },
    
    
  },
  reservations: [],
});

const getDefaultState = () => {
  return {
    user: {
      dbRef: null,
      username: "",
      about: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      company: null,
      linkedin: null,
      instagram: null,
      twitter: null,
      site: null,
      comments: false,
      candidates: false,
      smsEverything: false,
      smsSameEmail: false,
      smsNotifications: false,
      avatar: "",
      blockchainAddress: null,
      provider: null,
      hasSetAllowance: false,
      nfts: [],
      offers: [],
      bids: [],
      affiliate: {
        affiliate_code: "",
        affiliate_referral_link: "",
        comission: 0,
        comission_wallet: "",
        status: false
      },
      
      whitelist: {
        qty: 0,
        price: 20,
        expiration: ''
      }
    },
    reservations: [],
  };
};

// getters
const getters = {

  getUser: (state) => {
    return state.user;
  },
  getReservations: (state) => {
    return state.reservations;
  },
  getUsername: (state) => {
    return state.user.username;
  },
  getFullName: (state) => {
    return state.user.firstName + " " + state.user.lastName;
  },
  getAvatar: (state) => {
    return state.user.avatar;
  },
  getEmail: (state) => {
    return state.user.email;
  },
  getBids: (state) => {
    return state.user.bids
  },
  geOffers: (state) => {
    return state.user.offers
  },
  getBlockchainAddress: (state) => {
    return state.user.blockchainAddress;
  },
};

// actions
const actions = {
  async getUser({ dispatch }, payload) {
    dispatch("recoverUser", payload);
  },
  
  async recoverUser({ commit, dispatch }, payload) {
    try {
      const results = await getUserByAddress(payload.address);
      const reservations = await findAllSubcollections('clients', 'reservations', payload.address);
      //console.log("RESULTS",results)
      const user = results.doc;
      user.dbRef = results.doc.blockchainAddress;
      //console.log('recover user->', user);
      commit("setUser", user);
      commit("initResevations", reservations);
    } catch ($e) {
      //console.log($e)
      //console.log("ERROR:^^^")
      dispatch("saveUser", {address: payload.address, provider: payload.provider});
    }
    
  },
  async saveUser({ commit, state }, payload) {
    const clientRef = payload.address;   
 
    let user = Object.assign({}, state.user);
    user.blockchainAddress = payload.address;
    user.provider = payload.provider;
    user.dbRef = clientRef;
    await createObject('clients', user);
    await set("user", JSON.stringify(user));
    commit("setUser", user);
  },

  async updateUserAffiliate({ commit, state }, payload) {
    const user = JSON.parse(JSON.stringify(state.user));
    //console.log('---');
    //console.log(payload);
    //console.log('---');
    for (let field in payload.affiliate) {
      if (payload.affiliate[field] != null) {
        user.affiliate[field] = payload.affiliate[field]
      } 
    }
    //console.log(payload.affiliate)
    await editObject("clients", user.dbRef, user);
    await set("user", JSON.stringify(user));
    commit("setUser", user);
  },
  
  async updateUser({ commit }, payload) {
    //console.log('---');
    //console.log(payload);
    //console.log('---');
    const user = payload;
    await editObject('clients', user.dbRef, user);
    await set("user", JSON.stringify(user));
    commit("setUser", user);
  },
  async addNft({commit}, payload) {
    const results = await getClientByBlockChain(payload.address);
    //console.log(results);
    if (results.state) {
      const user = results.data;
      user.dbRef = results.dbRef;
      //console.log(user);
      const nfts = user.nfts;
      nfts.push(payload.nft);

      await db.collection("clients").doc(user.dbRef).update({nfts: nfts});

      commit('updateNfts', nfts);
    }
  },
  async logoutUser({ commit, dispatch }) {
    await del("user");
    commit("resetState");
    //block chain action
    dispatch("blockchain/delete", {}, {root: true});
  },
  async delete({ commit }) {
    await del("user");
    commit("resetState");
  },
  async setAllowance({commit}, payload) {
    await db.collection("clients").doc(payload.client).update({
      hasSetAllowance: true
    });

    commit("setAllwoance");
  },
  async newOffer({commit, state}, payload) {
    

    let offers = state.user.offers || [];
    offers.push(payload.offer);

    await db
    .collection("clients")
    .doc(state.user.dbRef)
    .update({ offers: offers });


    commit('updateOffers', offers);

  },

  async newPurchase({commit, state}, payload) {
    

    let nfts = state.user.nfts || [];
    nfts.push(payload.nft);

    await db
    .collection("clients")
    .doc(state.user.dbRef)
    .update({ nfts: nfts });


    commit('updateNfts', nfts);

  },

  async addReservation({commit, state}, payload) {
    const reservation = payload.reservation;
    const nft = payload.nft;
    const reservationData = await createSubCollection("clients", state.user.dbRef, 'reservations', reservation);
    nft.reservation = reservationData.data.subcollectionId;
    nft.reservationDate = reservation.date;
    nft.status = "reserved";
    await editObject('nfts', payload.nftRef, nft);
    const reservationObject = reservation;
    reservationObject.ref = reservationData.subcollectionId;

    commit("setReservations", reservation);
  },

  async rmReservation({commit, state}, payload) {
    const nft = payload.nft;
    await deleteSubcollection("clients", state.user.dbRef, 'reservations', payload.ref);
    nft.reservation = '';
    nft.reservationDate = '';
    await editObject('nfts', payload.nftRef, nft);

    commit("rmReservations", payload.id);
  }
  
};

// mutations
const mutations = {
  changeType(state, payload) {
    state.user.type = payload;
  },
  setUser(state, payload) {
    state.user = {...state.user, ...payload};
  },
  initResevations(state, payload) {
    state.reservations = [...payload];
  },
  setReservations(state, payload) {
    state.reservations.push(payload);
  },
  rmResevations(state, payload) {
    state.reservations.splice(state.reservations.findIndex(item => item.ref === payload), 1)
  },
  updateNfts(state, payload) {
    state.user.nfts = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAllwoance(state) {
    state.user.hasSetAllowance = true;
  },
  updateOffers(state, payload) {
    state.user.offers = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
