<template>
  <div
    class="bg-transparency flex items-center justify-start w-[95%] sm:w-full sm:max-w-[96%] lg:max-w-7xl mx-auto sm:m-0 rounded-lg absolute top-[320px] lg:max-w-7xl left-1/2 transform -translate-x-1/2 lg:-bottom-1/4 lg:h-[257px] overflow-hidden p-4 lg:px-8 lg:pt-8 lg:pb-0 z-35"
    style="
      background-color: #28E0BD;
    "
  >
    <div class="hidden lg:block w-full space-y-8 z-20">
      <div class="flex justify-between">
        <div class="flex flex-col items-start">
          <p class="text-4xl leading-10 font-extrabold text-altOne">
            Get your membership
          </p>
          <p class="text-4xl leading-10 font-extrabold text-white">
            Early bird gets the bear
          </p>
        </div>
        <div class="grid grid-cols-2">
          <div class="flex items-center justify-start gap-3">
            <CheckCircleIcon color="white" class="w-[23px] text-white" />
            <p class="text-lg xl:text-2xl leading-7 xl:leading-8 font-bold text-altOne">Early Mint Scaling Prices</p>
          </div>
          <div class="flex items-center justify-start gap-3">
            <CheckCircleIcon color="white" class="w-[23px] text-white" />
            <p class="text-lg xl:text-2xl leading-7 xl:leading-8 font-bold text-altOne">
              Mint Floor Price Locks I/O
            </p>
          </div>
          <div class="flex items-center justify-start gap-3">
            <CheckCircleIcon color="white" class="w-[23px] text-white" />
            <p class="text-lg xl:text-2xl leading-7 xl:leading-8 font-bold text-altOne">Membership Mint Levels</p>
          </div>
          <div class="flex  items-center justify-start gap-3">
            <CheckCircleIcon color="white" class="w-[23px] text-white" />
            <p class="text-lg xl:text-2xl leading-7 xl:leading-8 font-bold text-altOne">Lifetime Membership NFT</p>
          </div>
        </div>
      </div>
      <div class="w-full h-[70px] flex items-start space-x-4">
        <!-- HERE COMES THE METER -->
        <div class="w-full relative">
            <div class="w-full bg-white h-[42px] rounded-3xl relative overflow-hidden">
                <div class="w-[5%] h-full bg-altOne absolute top-0 left-0 flex items-center p-2">
                    <div class="w-full inline-flex items-center justify-between">
                        <lock-open :fill="'white'" />
                        <p class="w-6 h-full text-sm font-bold leading-tight text-center text-white">24</p>
                    </div>
                </div>
                <div class="pl-[5%] w-full flex items-center">
                    <div v-for="(mint, index) of mints" :key="index" :class="{'w-full': !mint.active, 'w-24': mint.active}">
                        <coming-minting-prices @click="displayPriceLockDisclosure" :price="mint.price" :number="mint.number" :active="mint.active" />
                    </div>
                </div>
            </div>
            <div class="pointer absolute -bottom-[23px] left-[4%]">
                <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8132 0L22.8003 19.0302H0.826169L11.8132 0Z" fill="#1B7B7B"/>
                </svg>
            </div>
        </div>
        <div class="action w-[167px]">
          <button @click="showCards" class="w-full bg-altOne rounded-3xl">
            <p class="w-full text-base leading-6 font-medium text-white my-[9px]">
              LEARN MORE
            </p>
          </button>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col items-center justify-center w-full lg:hidden z-20">
      <div class="flex flex-col items-start justify-center">
        <p class="text-[25px] w-full text-justify leading-[25px] font-bold text-altOne">
          Get your membership
        </p>
        <p class="text-[23px]  w-full text-justify leading-[23px] font-bold text-white">
          Early bird gets the bear
        </p>
      </div>

      <div class="w-full">
        <!-- HERE COMES THE METER -->
        <div class="h-full flex items-start py-4">
          <meter-mobile :mints="mints" />
            <div class="h-[220px] w-full flex flex-col items-center justify-between">
                <div v-for="(mint, index) of mints" :key="index" class="w-full">
                    <coming-minting-prices-mobile :price="mint.price" :number="mint.number" :active="mint.active" :minted="24" />
                </div>
            </div>
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <div class="flex items-center justify-start gap-3">
          <CheckCircleIcon color="white" class="w-[23px] text-white" />
          <p class="text-lg leading-7 font-bold text-altOne">Early Mint Scaling Prices</p>
        </div>
        <div class="flex items-center justify-start gap-3">
          <CheckCircleIcon color="white" class="w-[23px] text-white" />
          <p class="text-lg leading-7 font-bold text-altOne">Mint Floor Price Locks I/O</p>
        </div>
        <div class="flex items-center justify-start gap-3">
          <CheckCircleIcon color="white" class="w-[23px] text-white" />
          <p class="text-lg leading-7 font-bold text-altOne">Membership Mint Levels</p>
        </div>
        <div class="flex items-center justify-start gap-3">
          <CheckCircleIcon color="white" class="w-[23px] text-white" />
          <p class="text-lg leading-7 font-bold text-altOne">Lifetime Membership NFT</p>
        </div>
      </div>

      <div class="w-full py-5 z-20">
        <button class="w-full bg-altOne rounded-lg" @click="showCardsMobile">
          <p class="text-lg leading-8 font-normal py-2 text-white">
            LEARN MORE
          </p>
        </button>
      </div>
    </div>
    <Modal />
  </div>
</template>
  <script>
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { ref } from 'vue';
import ComingMintingPrices from './ComingMintingPrices.vue';
import LockOpen from '../Icons/LockOpen.vue';
import MeterMobile from './MeterMobile.vue';
import ComingMintingPricesMobile from './ComingMintingPricesMobile.vue';
import Modal from "@/components/Modals/Modal.vue";
import { useStore } from "vuex";
export default {
    components: { Modal, CheckCircleIcon, ComingMintingPrices, LockOpen, MeterMobile, ComingMintingPricesMobile },
    emits: ['on:showCards'],
  setup(props, { emit }) {
    const mints = ref([
        {
            price: 2,
            number: 272,
            active: true
        },
        {
            price: 4,
            number: 800,
            active: false
        },
        {
            price: 6,
            number: 1600,
            active: false
        },
        {
            price: 8,
            number: 2600,
            active: false
        },
        {
            price: 10,
            number: 3800,
            active: false
        },
        {
            price: 12,
            number: 5500,
            active: false
        }
    ]);
    const store = useStore();
    const displayPriceLockDisclosure = () => {
      store.dispatch("NotificationStore/SET_MODAL_TITLE", {
          title: "Floor Price Lock Disclosure",
        });
        store.dispatch("NotificationStore/SET_MODAL_MESSAGE", {
          message: `By purchasing a CIRKOL mNFT membership, you acknowledge and agree that the floor price for each level of membership is locked once the NFT is minted, and will remain locked until all membership NFTs have been minted. Additionally, you understand and agree that the locked floor prices represent the minimum value of each level of membership, and that all sales are final and non-refundable.
You acknowledge that the floor prices for each level of membership are subject to change by the project team or by mNFT members with our governance system to ensure the long-term viability of the project. Please note that the mNFT tokens will only be tradable on our proprietary NFT marketplace while locked. Please carefully consider your decision to purchase an mNFT membership.`,
        });

        store.dispatch("NotificationStore/SET_OPEN_MODAL");
    }
    const showCards = () => {
        emit('on:showCards');
    }
    const showCardsMobile = () => {
        emit('on:showCardsMobile');
    }
    return {mints, showCards, showCardsMobile, displayPriceLockDisclosure};
  },
};
</script>
<style >
    .bg-transparency::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('@/assets/images/cirkol-background.jpg');
        background-size: cover;
        opacity: .23;
        mix-blend-mode: normal;

    }
</style>