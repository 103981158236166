<template>
    <div class="inline-flex items-center justify-start w-56 h-8 relative w-full">
        <svg width="12" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3755 15.1455L0 8.00046L12.3755 0.855469L12.3755 15.1455Z" :fill="active ? '#1B7B7B': 'white'"/>
        </svg>

        <div class="w-full relative w-full h-8 inline-flex items-center justify-start rounded-r-lg" :class="{'bg-altThree-100': active, 'bg-white': !active}">
            <div class="bg-altOne w-[22%] h-8 absolute left-0 flex items-center justify-end pr-2" v-if="active"> <span class="text-white">{{ minted }}</span></div>
            <div class="absolute left-1" ><lock-close :fill="'#A4CACA'" v-if="!active" /> <lock-open :fill="'#A4CACA'" v-else /></div>
            <div class="flex-1">
                <div class="flex space-x-0.5 items-center justify-end flex-1 p-2">
                    <p class="text-sm font-bold leading-tight text-center " :class="{'text-altOne-200': !active, 'text-altOne': active}">{{ number }}</p>
                    <div class="flex items-start justify-start pb-1">
                        <p class="text-base font-medium leading-3 text-center" :class="{'text-altOne-200': !active, 'text-altOne': active}">@</p>
                    </div>
                    <div class="flex items-center justify-center">
                        <p class="text-sm font-bold leading-tight text-center" :class="{'text-altOne-300': !active, 'text-altOne': active}">{{price}}</p>
                        <p class="w-2/3 h-full text-base text-altOne-300">
                            <ETHColor v-if="active" :size="3" />
                            <ETHCustom v-else :size="3" :color="'#76B0B0'" />
                        </p>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>
<script>
import LockClose from '../Icons/LockClose.vue'
import LockOpen from '../Icons/LockOpen.vue'
//import ETHalt from './ETHalt.vue'
import ETHColor from './ETHColor.vue'
import ETHCustom from './ETHCustom.vue'
export default {
  components: { ETHColor, LockClose, LockOpen, ETHCustom /*ETHalt*/ },
    
    props: ['price', 'number', 'active', 'minted'],
    setup() {
        
    },
}
</script>