<template>
<div class="inline-flex flex-col items-start justify-between w-8 h-56 bg-white rounded-3xl relative overflow-hidden">
    <div class="h-[7%] w-full bg-altOne absolute"></div>
    <div class="w-full h-full border-b border-b-altThree-50" :class="{'bg-altThree-100': mint.active}" v-for="(mint, index) of mints" :key="index"></div>
</div>
</template>
<script>
export default {
    props: ['mints'],
    setup() {
        
    },
}
</script>