<template>
  <div>
    <div
      class="cursor-pointer fixed right-0 top-0 rounded-lg z-50 bg-transparent"
      :class="[color]"
      @click="toggleMenu"
    >
      <div class="flex p-4 bg-transparent">
        <div class="m-auto bg-transparent">
          <cirkol-menu-close :state="localAnimate" />
        </div>
      </div>
    </div>
    <div
      :class="{ 'right-0': localAnimate, '-right-full': !localAnimate }"
      class="
        transition-all
        ease
        duration-500
        w-full
        lg:max-w-2xl lg:w-m-2xl
        overflow-hidden
        fixed
        z-40
        top-0
        shadow
        bg-white
        inset-y-0
        rounded-bl-xl
      "
    >
      <div
        class="
          h-full
          col-span-4
          flex-1 flex flex-col
          min-h-0
          bg-white
          font-inter font-medium
          pt-6
        "
      >
        <div class="flex space-x-2 lg:space-x-8 absolute top-8 left-10">
          <a
            v-for="item in social"
            :key="item.name"
            :href="item.href"
            target="_blank"
            class="
              text-gray-700
              hover:text-pink hover:text-pink
              border border-gray-700
              hover:border-pink
              flex
              items-center
              justify-center
              w-14
              h-14
            "
            rel="noopener noreferrer"
          >
            <span class="sr-only">{{ item.name }}</span>
            <font-awesome-icon :icon="item.icon" size="xl" />
          </a>
        </div>
        <div class="flex-1 flex flex-col pt-10 pb-10 overflow-y-auto">
          <nav
            class="mt-10 flex-1 flex flex-col justify-center space-y-1"
            aria-label="Sidebar"
          >
            <span v-for="item in navigation" :key="item.name">
              <router-link
                v-if="item.local"
                :to="{ name: item.href }"
                @click="closeMenu"
                :class="[
                  currentRouteName === item.href
                    ? 'bg-gray-100 text-gray-600'
                    : 'text-gray-700 hover:bg-white hover:text-pink',
                  'group flex items-center pl-10 py-2 lg:py-8 font-light text-3xl lg:text-5xl rounded-md uppercase',
                ]"
              >
                <span class="flex-1">
                  {{ item.name }}
                </span>
              </router-link>
              <a
                :href="item.href"
                :class="[
                  currentRouteName === item.href
                    ? 'bg-gray-100 text-gray-600'
                    : 'text-gray-700 hover:bg-white hover:text-pink',
                  'group flex items-center pl-10 py-2 lg:py-8 font-light text-3xl lg:text-5xl rounded-md uppercase',
                ]"
                v-else
              >
                <span class="flex-1">
                  {{ item.name }}
                </span></a
              >
            </span>
          </nav>
        </div>
        <div
          class="flex-shrink-0 p-10 space-y-8 border-t border-gray-300"
          v-if="blockchainAddress"
        >
          <router-link
            :to="{ name: 'MyAccount' }"
            @click="closeMenu"
            class="flex-shrink-0 w-full group block"
          >
            <span class="font-light text-3xl lg:text-5xl text-gray-600"
              >ACCOUNT</span
            >
            <div class="flex items-center">
              <div>
                <div class="flex items-center space-x-1">
                  <p class="text-3xl font-light text-pink hover:underline">
                    {{
                      blockchainAddress
                        ? blockchainAddress.replace(
                            blockchainAddress.substring(
                              8,
                              blockchainAddress.length - 4
                            ),
                            "...."
                          )
                        : "N/A"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
          <div class="group flex items-center">
            <a
              href="#"
              @click.prevent="logout"
              class="
                text-3xl
                lg:text-5xl
                uppercase
                font-light font-inter
                cursor-pointer
                text-gray-600
                hover:text-pink
              "
              >Disconnect</a
            >
          </div>
        </div>

        <div
          class="flex items-center p-10 space-y-4 border-t border-gray-300"
          v-else
        >
          <a
            href="javascript:void(0)"
            @click.prevent="login"
            class="
              text-3xl
              lg:text-5xl
              uppercase
              font-light
              cursor-pointer
              text-primary-500
              hover:text-pink
              font-light
              font-inter
            "
            >Connect</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import { storage } from "../../firebase/firebase";
import CirkolMenuClose from "../Icons/CirkolMenuClose.vue";
import { watch } from "@vue/runtime-core";

const navigation = [
  { name: "Join", icon: "", href: "/" },
  { name: "Lightpaper", icon: "", href: "https://www.cirkol.com/#lightpaper" },
  { name: "Roadmap", icon: "", href: "https://www.cirkol.com/#roadmap" },
  { name: "Team", icon: "", href: "https://www.cirkol.com/#team" },
];

const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/cirkol_",
    icon: "fa-brands fa-twitter fa-2xl",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/cirkol_/",
    icon: "fa-brands fa-instagram fa-2xl",
  },
  {
    name: "Discord",
    href: "https://discord.com/channels/987423455521701948/987423457316851727",
    icon: "fa-brands fa-discord fa-2xl",
  },
];

export default {
  props: {
    animate_menu: {
      type: Boolean,
      default: false,
    },
    outsideColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    //ChartSquareBarIcon,
    CirkolMenuClose,
  },

  emits: ["on:login", "on:logout", "on:close","toggleMenu"],
  CirkolMenuClose,
  setup(props, { emit }) {
    const localAnimate = ref(false);
    const store = useStore();
    const { outsideColor, animate_menu } = toRefs(props);
    const color = ref(outsideColor.value); 

    const blockchainAddress = computed(
      () => store.getters["user/getBlockchainAddress"]
    );

    const user = computed(() => store.getters["user/getUser"]);
    const avatar = ref();

    const login = () => {
      document.body.classList.remove('modal-open')
      emit("on:login");
    };

    const logout = () => {
      /* const instance = currentInstance.value;
      const web3 = instance();
      console.log(web3.eth.currentProvider);
      web3.currentProvider.disconnect(); */
      document.body.classList.remove('modal-open')

      emit("on:logout");
    };

    watch(animate_menu, (value) => {
      console.log('here');
      localAnimate.value = value;
      if (value) { 
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }  


    });

    const getAvatar = async () => {
      const image = store.getters["user/getAvatar"];

      if (image) {
        var storageRef = storage.ref();
        const url = await storageRef.child(image).getDownloadURL();
        avatar.value = url;
      }
    };

    const toggleMenu = () => {
      console.log('close');
      emit("toggleMenu");
    }

    const closeMenu = () => {
      localAnimate.value = false;
      console.log('close');
      document.body.classList.toggle('modal-open')
      emit("on:close");
    };

    return {
      navigation,
      login,
      logout,
      blockchainAddress,
      getAvatar,
      avatar,
      user,
      social,
      color,
      localAnimate,
      closeMenu,
      toggleMenu
    };
  }
};
</script>

<style >
.userIcon {
  color: #9ca3af;
}
.overlay-difference {
  mix-blend-mode: difference !important;
}
</style>