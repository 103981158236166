<template>
  <div class=" overflow-hidden w-full relative" :class="bg">
    <slot name="badge" />
    <div class="slider relative rounded-lg overflow-hidden">
      <div v-if="nft.isMinted" class="absolute right-4 top-4 z-10 h-[71px] w-[71px] bg-white/75 rounded-full flex items-center justify-center text-center">
        <div class="font-bold h-[61.09px] w-[61.09px] text-[#28E0BD] rounded-full text-[13.4531px] bg-white flex items-center justify-center text-center" >MINTED</div>
      </div>
      
      <img v-lazy="nft.imageUrl" @click="goToDetails" class="w-full h-full object-cover transition-all duration-300 transform scale-100 hover:scale-110" />
    </div>

    
  </div>
</template>
<script>
//import Stats from "./NftStats.vue";
import { onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";

// import { useRouter } from 'vue-router'

export default {
  props: {
    nft: Object, 
    bg: String, 
    badgecolor: String, 
    url: String, 
    showArrows: String, 
    create: Boolean, 
    price: Number,
    amount: Number
  },

  components: {
    //Stats,
  },

  setup(props) {
    const router = useRouter();

    const currentPrice = ref(props.nft.mintinPrice);

    // const router = useRouter()
    onMounted(() => {
      currentPrice.value = parseInt(props.amount) ? parseFloat(props.price) : props.nft.mintinPrice;
    });

    const goToDetails = () => {
      if (props.vault.status == "minted") {
        router.push(props.url);
      } else if (
        props.vault.status == "applied" ||
        props.vault.status == "pending"
      ) {
        router.push("/vault/create/step/2/" + props.vault.dbRef);
      } else if (props.vault.status == "approved") {
        router.push("/vault/create/step/3/" + props.vault.dbRef);
      }
    };

    return {
      goToDetails,
      currentPrice
    };
  },
};
</script>