<template>
<div >
    <div class="mt-6 grid grid-cols-1
                gap-x-4
                sm:gap-x-4 space-y-4 lg:space-y-0" :class="[`lg:grid-cols-${localFilters.length + 1}`]">
        
        <div class="sm:items-start text-left" v-for="(filter, index) of localFilters" :key="index">
            
            <div
            class="
                
            "
            >
            <div class="relative">
                <ListBox :options="filter.options" :name="filter.name" :filter="filter.key" @on:selected="filterNow" />
                
            </div>
            
            </div>
        </div>
        <div class="hidden sm:flex flex-row items-center mt-6 space-x-2">
        <div class="flex-1"><search v-if="false" /> </div>
        <minted @on:filter="filterMinted" />
    </div>
    </div>
    <div class="flex sm:hidden flex-row items-center mt-6 space-x-2">
        <minted @on:filter="filterMinted" />
    </div>
</div>
</template>
<script>
import { ref, toRefs } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
import ListBox from '@/components/Forms/ListBox.vue'
import Search from '../Forms/Search.vue';
import Minted from '../Forms/Minted.vue';
export default {
    components: { ListBox, Search, Minted },
    props: ['filters'],
    emits: ['on:filter'],
    setup(props, {emit}) {
        const { filters } = toRefs(props);
        const localFilters = ref(filters);
        let selectedFilters = [];

        watch(filters, (value) => {
            localFilters.value = value;
        });

        const filterNow = (event) => {
            const filtered = selectedFilters.filter(item => {
                return item.name !== event.name
            });
            if (event.value !== 'all') {
                filtered.push(event);
            }
            selectedFilters = filtered;
            emit('on:filter', {filtersSelected: selectedFilters});

        }

        const filterMinted = (event) => {
            const filtered = selectedFilters.filter(item => {
                return item.name !== 'isMinted'
            });
            selectedFilters = filtered;
            selectedFilters.push({name: 'isMinted', value: event.minted});
            emit('on:filter', {filtersSelected: selectedFilters});
        }

        return {
            localFilters,
            filterNow,
            filterMinted
        }
    },
}
</script>