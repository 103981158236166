<template>
   <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.4999 7.19961V5.59961C4.4999 3.39047 6.29076 1.59961 8.4999 1.59961C10.709 1.59961 12.4999 3.39047 12.4999 5.59961V7.19961C13.3835 7.19961 14.0999 7.91595 14.0999 8.79961V12.7996C14.0999 13.6833 13.3835 14.3996 12.4999 14.3996H4.4999C3.61625 14.3996 2.8999 13.6833 2.8999 12.7996V8.79961C2.8999 7.91595 3.61625 7.19961 4.4999 7.19961ZM10.8999 5.59961V7.19961H6.0999V5.59961C6.0999 4.27413 7.17442 3.19961 8.4999 3.19961C9.82538 3.19961 10.8999 4.27413 10.8999 5.59961Z" :fill="fill"/>
</svg>


</template>
<script>
export default {
    props: ['fill'],
    setup() {
        
    },
}
</script>