// initial state
const state = () => ({
    referral: "",
  });
  
  // getters
  const getters = {
    getReferral: (state) => {
      return state.referral;
    },
  };
  
  // actions
  const actions = {
    async saveReferral({ commit }, payload) {
      commit("setReferral", payload);
    },
  };
  
  // mutations
  const mutations = {
    setReferral(state, payload) {
      state.referral = payload
      //state = Object.assign({}, payload);
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };