<template>
  <div class="artist font-inter">
    <div class="relative bg-white ">
      <div class="relative z-20">
        <div class="text-center mx-auto w-full ">
          <h1
            class="
              mt-0
              text-64px
              leading-10
              flex
              items-center
              justify-center
              font-bold
              text-white
              tracking-tight
              sm:text-4xl
              header-height
            "
            style="
              background: url('./images/CIRKOL_Header-market.jpg') rgba(0, 0, 0, 0.5);
              background-blend-mode: multiply;
              background-size: cover;
            "
          >
            CIRKOL MIAMI
          </h1>
        </div>
        <div class="fixed lg:hidden top-8 left-4 z-20 flex space-x-4">
          <div class="filter-button-small">
            <a href="#" @click.prevent="showFiltersMobile = !showFiltersMobile" class="block  rounded-lg w-full h-full flex items-center justify-center hover:text-pink transition-all ease duration-500" :class="testColor"><Bars3CenterLeftIcon class="w-8 h-8" /></a>
          </div>
          <div class="filter-button-small ">
            <a href="#" @click.prevent="showSeriesMobile = true" class="block w-full h-full flex items-center rounded-lg justify-center  hover:text-pink transition-all ease duration-500" :class="testColor"><InformationCircleIcon class="w-8 h-8" /></a>
          </div>
          
        </div>

        <Teleport to="body">
        <div class="p-8 bg-white fixed w-screen h-screen top-0 left-0 z-50" ref="filters" v-show="showFiltersMobile">
          <div class="sm:block absolute top-0 right-0 pt-4 pr-4 close flex justify-end"><a href="#" @click.prevent="showFiltersMobile = false" class="text-black hover:text-pink w-10 h-10"><icon-close /></a></div>
          <div class="pt-8"><Filters :filters="localFilters" @on:filter="showNfts"  /></div>
          
        </div>
        </Teleport>
        <MintCount @on:showCards="showSeries = true" @on:showCardsMobile="showSeriesMobile = true" />
      </div>
      <div class="px-4 2xl:px-0 sm:block lg:pt-6 -mt-8 lg:mt-0 relative z-10 max-w-7xl m-auto">
        <div class="h-[465px] lg:h-[135px]"></div>
      <div class=" mx-auto ">
        <div class="w-full flex sm:space-x-4 sm:flex-row sm:items-center">
          <div class="flex-grow space-y-2">
            
            <Stats :stats="stats" />
          </div>
          <div class="hidden lg:block filter-button border-2 border-gray-200 rounded-lg ">
            <a href="#" @click.prevent="showSeries = true" class="block w-full h-full flex items-center justify-center hover:text-pink"><InformationCircleIcon class="w-8 h-8" /></a>
          </div>
          <div class="hidden lg:block filter-button border-2 border-gray-200 rounded-lg">
            <a href="#" @click.prevent="showFilters = !showFilters" class="block w-full h-full flex items-center justify-center hover:text-pink"><Bars3CenterLeftIcon class="w-8 h-8" /></a>
          </div>
        </div>
      </div>
      <transition name="expand">
      <div class="max-w-7xl mx-auto hidden sm:block" ref="filters" v-show="showFilters">
        <Filters :filters="localFilters" @on:filter="showNfts"  />
      </div>
      </transition>
      </div>
    </div>

    <div class="pb-20 pt-6 white bg-white">
      <div
        v-if="isLoading"
        class="
          box-border
          max-w-7xl
          grid grid-cols-1
          lg:grid-cols-4
          gap-4
          mx-auto
          before:box-inherit
          after:box-inherit
        "
      >
        <section
          v-for="index in 12"
          :key="index"
          class="relative rounded-lg mx-3 mb-8 md:mx-0 md:mb-5 md:mt-0"
        >
          <div class="content-center">
            <nft-skeleton />
          </div>
        </section>
      </div>
      <div
        class="
          box-border
          max-w-7xl
          px-4
          2xl:px-0
          grid grid-cols-1
          sm:grid-cols-2
          lg:grid-cols-4
          gap-4
          mx-auto
          before:box-inherit
          after:box-inherit
        "
        ref="scrollComponent"
        v-else
      >
        <section
          v-for="art in data.gallery"
          :key="art.id"
          class="relative rounded-lg"
        >
          <div
            class="content-center"
            :class="{ 'flex flex-nowrap h-48': art.id == 1 }"
          >
            <router-link :to="artLink(art)" class="cursor-pointer">
              <nft-item :nft="art" :price="price" :amount="amount" />
            </router-link>
          </div>
        </section>
        
      </div>
      <div class="text-center px-4 max-w-4xl mx-auto pb-5 pt-5">
        <Button @click="getMore" customClass="w-full" :size="'large'">LOAD MORE</Button>
      </div>
      
    </div>
    <crikol-modal :show="showSeriesMobile" @on:close="closeSeries" />
    <crikol-modal-deskop :show="showSeries" @on:close="closeSeries" />
  </div>
</template>


<script>
// @ is an alias to /src
//import Gallery from '@/components/Gallery/Gallery';
import NftItem from "@/components/Shared/NftItem.vue";
//import { useRoute } from 'vue-router';
import { computed, ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import { getNftsByCollection, nftFilters, getMintedNftsCount, filterNftsPaged, getReservedNftsCount } from "../../firebase/nfts";
import { storage } from "../../firebase/firebase";
import NftSkeleton from "@/components/Shared/NftSkeleton.vue";
import { getWhiteList } from "../../blockchain/index";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Button from '@/components/Layouts/Button.vue';

import Stats from "@/components/Shared/Stats.vue";
import MintCount from "@/components/Shared/MintCount.vue";
import Filters from "@/components/Shared/Filters.vue";
import { Bars3CenterLeftIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
import IconClose from '../../components/Icons/IconClose.vue';
import CrikolModal from '@/components/Modals/CrikolModal.vue';
import CrikolModalDeskop from '../../components/Modals/CrikolModalDeskop.vue';

//import { useStore } from 'vuex';

/* const data = {
    artist: 'Mario Sanchez',
    title: 'Mario Sanchez Collection',
    artist_name: 'Mario Sanchez',
    bio_title: 'Mario Sanchez October 7th, 1908 - April 28th, 2005',
    bio: 'Critically acclaimed as the most important Cuban American Folk Artist of the 20th Century he received one-man exhibitions in major museums from New York’s American Folk Art & Museum of the City of New York, Bacardi Museum & Cuba’s National Museum of Fine Art. His work resides in the Smithsonian, Whitney & American Folk Art Museums. His motto was "One Race, the Human Race". He believed in the Great American Dream with a humorous twist.  Mario Sanchez passion was depicting the people of Key West & Tampa.  His unique technique of sketches & intaglios of Florida & Cuba treasured history involve the humanistic values of a rich cultural community filled with the traditions of immigrants of diverse geographic and ethnic origins. ',
    img: '/images/art-7.jpg',

 } */

export default {
  components: {
    //Gallery
    NftItem,
    Button,
    MintCount,
    NftSkeleton,
    Stats,
    Filters,
    Bars3CenterLeftIcon,
    InformationCircleIcon,
    IconClose,
    CrikolModal,
    CrikolModalDeskop
  },
  emit: ["on:connect", 'on:scroll'],
  setup(props, { emit }) {
    const route = useRoute();
    const connect = computed(() => route.params.connect);
    const referral = computed(() => route.query.referral);
    const store = useStore();
    //const collectionRef = route.params.ref;
    const addr = computed(() => store.getters["blockchain/getCurrentAddress"]);
    const instance = computed(() => store.getters["blockchain/getInstance"]);
    const showFilters = ref(false);
    const showFiltersMobile = ref(false);
    const price = ref(0);
    const amount = ref(0);
    const localFilters = ref(nftFilters);
    const filters = ref();
    const stats = ref([
      {
        name: "mNft",
        value: 5500,
      },
      {
        name: "Minted",
        value: 0,
      },
      {
        name: "Reserved",
        value: 500,
      },
    ]);

    //const storage = firebase.storage();
    const currentPage = ref(1);
    const scrollComponent = ref();
    const testColor = ref(["bg-white"]);
    const data = ref({
      title: "Collection Name",
      artist: "Manfred",
      artist_name: "Manfred",
      bio_title: "This is the bio",
      bio: "This is the bio",
      img: "./images/mocks/nfts/img_01.jpg",
      gallery: [],
    });
    const isLoading = ref(true);
    const isFiltered = ref(false);
    const showSeries = ref(false);
    const showSeriesMobile = ref(false);
    

    const closeSeries = () => {
      showSeries.value = false;
      showSeriesMobile.value = false;
    }

    const getData = async () => {
      const gallery = await getNftsByCollection(
        process.env.VUE_APP_CATEGORY,
        currentPage.value
      );
      

      data.value.gallery = await Promise.all(
        gallery.map(async (item) => { 
          const data = {
          bearId: item.id,
          id: item.dbRef,
          title: item.title,
          href: "/admin/artwork/" + item.collection + "/" + item.dbRef,
          size: item.size,
          category: "mnft-miami",
          collection: item.collection,
          imageUrl: await getFullImageURL(item.imageUrl),
          isMinted: item.isMinted,
          mintinPrice: item.mintinPrice,
          reservation: item.reservation,
        };

        return data;
          
        })
      );
      console.log("GAL:", data)
      // doc.data() is never undefined for query doc snapshots
      data.value.artist_name = "MNFT-MIAMI";
      data.value.title = "MNFT-MIAMI";
      data.value.bio_title = "MNFT-MIAMI";
      data.value.lastName = "MNFT-MIAMI";
      data.value.bio = "MNFT-MIAMI";
      
      isLoading.value = false;

      const counts = await getMintedNftsCount();
      const reserved = await getReservedNftsCount();
      const minted = counts.minted

      const sts = stats.value;
      console.log(sts);
      sts[1].value = minted;
      sts[2].value = 500 - reserved - minted;

      stats.value = sts;
    };

    const getFullImageURL = async (item) => {
      if (item) {
        var storageRef = storage.ref();
        const imageUrl = await storageRef.child(item).getDownloadURL();

        return imageUrl;
      }
    };

    const getMore = async (event) => {
      currentPage.value++;
      let gallery;
      if (isFiltered.value) {
        gallery = await filterNftsPaged({filter: event.filtersSelected}, currentPage.value);
      } else {
         gallery = await getNftsByCollection(
          process.env.VUE_APP_CATEGORY,
          data.value.gallery[data.value.gallery.length - 1].bearId
        );
      }
     
      const newItems = await Promise.all(
        gallery.map(async (item) => {
          const data = {
            bearId: item.id,
            id: item.dbRef,
            title: item.title,
            href: "/admin/artwork/" + item.collection + "/" + item.dbRef,
            size: item.size,
            category: "mnft-miami",
            mintinPrice: item.mintinPrice,
            collection: item.collection,
            imageUrl: await getFullImageURL(item.imageUrl),
          };

          return data;
        })
      );
      data.value.gallery.push(...newItems);
    };

    const handleScroll = async () => {
      if(window.scrollY >= 360) {
        testColor.value = ['bg-black', "text-white"]
        emit('on:scroll', {color: "text-black"})
      } else {
        testColor.value = ['bg-white', "text-black"]
        emit('on:scroll', {color: "text-white"})
      }

      
    };

    const artLink = (artwork) => {
      return "/artwork/" + artwork.collection + "/" + artwork.id + "?referral="+referral.value;
    };

    const showNfts = async (event) => {
      isFiltered.value = true;
      isLoading.value = true;
      currentPage.value = 1;

      const gallery = await filterNftsPaged({filter: event.filtersSelected}, currentPage.value);

      data.value.gallery = await Promise.all(
        gallery.map(async (item) => {
          const data = {
            bearId: item.id,
            id: item.dbRef,
            title: item.title,
            href: "/admin/artwork/" + item.collection + "/" + item.dbRef,
            size: item.size,
            category: "mnft-miami",
            collection: item.collection,
            imageUrl: await getFullImageURL(item.imageUrl),
            isMinted: item.isMinted,
            mintinPrice: item.mintinPrice,
          };

          return data;
        })
      );

      isLoading.value = false;

    }

    const showMinted = async (event) => {
      
        if (event) {
          await loadMinted();
        } else {
          await getData();
        }
    }

    const loadMinted = async () => {
      isFiltered.value = true;
      isLoading.value = true;
      currentPage.value = 1;

      const gallery = await filterNftsPaged({filter: [{name: 'isMinted', value: true}]}, currentPage.value);

      data.value.gallery = await Promise.all(
        gallery.map(async (item) => {
          const data = {
            bearId: item.id,
            id: item.dbRef,
            title: item.title,
            href: "/admin/artwork/" + item.collection + "/" + item.dbRef,
            size: item.size,
            category: "mnft-miami",
            collection: item.collection,
            imageUrl: await getFullImageURL(item.imageUrl),
            isMinted: item.isMinted,
            mintinPrice: item.mintinPrice,
          };

          return data;
        })
      );

      isLoading.value = false;
    }

    const showText = () => {
       store.dispatch("NotificationStore/SET_MODAL_TITLE", {
          title: "",
        });
        store.dispatch("NotificationStore/SET_MODAL_MESSAGE", {
          message: `<p class="text-base leading-7 font-normal">CIRKOL blends decentralized technology with our innate desire to connect with people in the physical world. Once the project treasury goal is achieved, we will begin to act on development goals for a hotel and beach club in Miami, FL.  Ultimately, our CIRKOL mNFT(membership Non Fungible Token) holders will have prime access to utilize incredible on - property amenities.</p>

                    <p class="text-base leading-7 font-normal">mNFT holders will also have the opportunity to buy and resell rNFTs (reservation Non Fungible Tokens) that operate as a decentralized booking platform for the hotel and beach club. CIRKOL Miami, our first collection features artwork from Miami artist, Manfred Delgado, and is limited to an exclusive 5500 mNFTs minted using our proprietary marketplace.</p>`,
        });
      store.dispatch("NotificationStore/SET_MODAL_BUTTONS", {
        buttons: [
          { name: "JOIN YOUR CIRKOL", btnStyle: "primary", action: function () {
            store.dispatch("NotificationStore/SET_OPEN_MODAL");
          } },
          
        ],
      })
      store.dispatch("NotificationStore/SET_OPEN_MODAL");

    }

    onMounted(async () => {
      if (connect.value == "yes") {
        emit("on:login");
      }
      await store.dispatch("blockchain/initWallets");
      await store.dispatch("blockchain/getBlockChain");
      if (addr.value) {
        const mintValues = await getWhiteList(instance.value, addr.value);
        price.value = parseFloat(mintValues.price);
        amount.value = parseFloat(mintValues.amount);
      }
      await getData();
      window.addEventListener("scroll", handleScroll);
      
      showFilters.value = false;

    });

    onUnmounted(async () => {
      window.removeEventListener("scroll", handleScroll);

    });

    return {
      // navigation,
      // features,

      data,
      getMore,
      scrollComponent,
      artLink,
      isLoading,
      price,
      amount,
      stats,
      localFilters,
      showNfts,
      showMinted,
      showFilters,
      filters,
      showText,
      showFiltersMobile,
      testColor,
      closeSeries,
      showSeries,
      showSeriesMobile
      // footerNavigation,
    };
  },
};
</script>
<style scoped>
.header-height {
  height: 442px;
}

.filter-button {
  height: 108px;
  width: 108px;
}

.filter-button-small {
  height: 54px;
  width: 54px;
}

.expand-leave-active,
.expand-enter-active {
  transition: all 750ms ease;
  overflow: hidden;
}

.expand-enter-to,
.expand-leave-from {
  height: 70px;
}

.expand-enter-from,
.expand-leave-to {
  opacity: 0;
  height: 0;
}

.modal-filter {
  width: 320px;
}

.text-64px {
  font-size: 68px;
  line-height: 3.5rem;
}

</style>