<template>
    <button @mouseover="hover = true" @mouseleave="hover = false" class="group inline-flex space-x-0.5 items-center justify-center w-full h-[42px] px-2 border-r border-r-altThree-50 relative hover:bg-pink-50 hover:text-white" :class="{'bg-green-100': active, 'bg-white': !active}">
        <div class="absolute left-2" v-if="!active">
            <lock-close class="fill-[#A4CACA] group-hover:fill-pink" />
        </div>
        <p class="text-sm font-bold leading-tight text-center text-altOne-200 group-hover:text-pink">{{number}}</p>
        <div class="flex items-start justify-start pb-1">
            <p class="text-base font-medium leading-3 text-center text-altOne-200 group-hover:text-pink">@</p>
        </div>
        <div class="flex space-x-0.5 items-center justify-start">
            <p class="text-sm font-bold leading-tight text-center text-altOne-300 group-hover:text-pink">{{ price }}</p>
        <div class="w-2 h-3">
            <ETHColor v-if="active" :size="3" />
            <ETHCustom v-else-if="!hover" :size="3" :color="'#76B0B0'" />
            <ETHCustom v-else :size="3" :color="'#D937BA'"/>
        </div>
        </div>
    </button>
</template>
<script>
import { ref } from 'vue'
import LockClose from '../Icons/LockClose.vue'
// import ETHalt from './ETHalt.vue'
import ETHColor from './ETHColor.vue'
import ETHCustom from './ETHCustom.vue'
export default {
  components: { ETHColor, LockClose, ETHCustom },
    props: ['price', 'number', 'active'],
    setup() {      
        const hover = ref(false)
        return {
            hover,
        }  
    },
}
</script>