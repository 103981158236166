<template>
<div class="">
    <label for="search" class="sr-only">Search</label>
    <div class="relative rounded-md ">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
        <MagnifyingGlassIcon class="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
    </div>
    <input type="text" name="search" id="search" class="block w-full rounded-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:leading-6" placeholder="Search" />
    </div>
</div>
</template>
<script>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
export default {
    components: { MagnifyingGlassIcon },
    setup() {
        
    },
}
</script>