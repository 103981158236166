<template>
    <div 
      :class="`h-${iconSize} w-${iconSize} justify-center items-center`"
    > 
    
    <svg xmlns="http://www.w3.org/2000/svg" :class="`h-${iconSize } w-${iconSize}`" viewBox="0 0 784.37 1277.39"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_1421394342400">
       <g>
        <polygon :fill="iconColor" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "/>
        <polygon :fill="iconColor" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 "/>
        <polygon :fill="iconColor" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "/>
        <polygon :fill="iconColor" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89 "/>
        <polygon :fill="iconColor" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33 "/>
        <polygon :fill="iconColor" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33 "/>
       </g>
      </g>
     </g></svg>
    
    
    </div>
    </template>
    
    <script>
    import { ref, toRefs } from '@vue/reactivity'
    import { onMounted, watch } from '@vue/runtime-core'
    export default {
      props: ['color', 'size'],
      setup(props) {
        const { color, size } = toRefs(props)
    
        const iconColor = ref("#A0A4B1")
        const iconSize = ref("5")
    
        onMounted(() => {
          iconColor.value = color.value || "#A0A4B1"
          iconSize.value = size.value || "5"
        })
    
        watch(color, (val) => {
          iconColor.val = val
        })
        watch(size, (val) => {
          iconSize.val = val
        })
    
        return {
          iconColor,
          iconSize
        }
      },
    }
    </script>